<div [class]="'flex flex-'+alignment">
  @for (item of items; track item.key) {
    <div class="check-item flex gap-4 p-2 w-max" (click)="$event.stopPropagation()">
      <p-radioButton
        [value]="item.key"
        [inputId]="item.key + ''"
        (onClick)="onValueChange($event.value)"
        [styleClass]="styleClass"
        [(ngModel)]="value"
        (ngModelChange)="onValueChange($event)"
        [ngClass]="{'ng-invalid ng-dirty':invalid}"
      />
      <label class="capitalize" [for]="item.key"> {{ item.label }} </label>
    </div>
  }
</div>
