import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SignalService} from '@shared/core/services/signal.service';
import {PrimeNGConfig} from 'primeng/api';

export type Language = 'ar' | 'en';

@Injectable({
  providedIn: 'root',
})
export class I18nService {

  constructor(private primeNGConfig: PrimeNGConfig,
              private translateService: TranslateService,
              private signalService: SignalService) {
  }

  setUpLanguages() {
    this.translateService.addLangs(['en', 'ar']);
    this.translateService.setDefaultLang('en');
    this.updatePrimeNGLang();
    this.translateService.use(this.signalService.language());
  }

  private updatePrimeNGLang() {
    this.translateService.get('primeng').subscribe(res => this.primeNGConfig.setTranslation(res));
  }

  translatePhrase(phrase: string, params?: object): string {
    return this.translateService.instant(phrase, params);
  }

  switchLanguages(lang: Language) {
    this.signalService.language.set(lang);
    this.translateService.use(this.signalService.language());
    this.updatePrimeNGLang();
  }

  getCurrentLang(): string {
    return this.translateService.currentLang || this.translateService.getDefaultLang();
  }

  onLangChange() {
    return this.translateService.onLangChange;
  }
}
