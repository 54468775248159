<div class="container card flex justify-content-center">
  <p-progressSpinner
    [style]="{ width: '50px', height: '50px' }"
    ariaLabel="loading"
    [styleClass]="styleClass"
    strokeWidth="4"
    fill="transparent"
    animationDuration="2"
  />
</div>
