import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@Component({
    selector: 'app-progress-spinner',
    standalone: true,
    imports: [ProgressSpinnerModule, TranslateModule, CommonModule],
    templateUrl: './progress-spinner.component.html',
    styleUrl: './progress-spinner.component.scss',
})
export class ProgressSpinnerComponent {
    @Input() styleClass: string = '';
}
