@if (!isLoginPage) {
  <app-navbar [items]="navbarItems" [isMainItem]="true" [styleClass]="'main-menu'">
    <div class="flex items-center hover:cursor-pointer">
    <span class="whitespace-nowrap overflow-ellipsis font-bold max-w-40 truncate capitalize"
          [pTooltip]="username"
          tooltipPosition="top">
      {{ username }}
    </span>
      <app-profile-dropdown></app-profile-dropdown>
    </div>
  </app-navbar>
}
<div class="p-4 mt-28" [ngClass]="{'blocked-bg':spinnerService.showSpinner}">
  <router-outlet/>
  <p-toast position="bottom-right"/>
</div>
@if (spinnerService.showSpinner) {
  <app-progress-spinner [styleClass]="'absolute left-2/4 top-2/4 z-[9999]'"/>
}
