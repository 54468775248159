<div [ngClass]="{ 'menubar-container': isMainItem }">
  <p-menubar [styleClass]="styleClass" [model]="items" [autoDisplay]="false">
    <ng-template pTemplate="start">
      <span class="pi pi-home home-icon" (click)="router.navigate([''])"></span>
    </ng-template>
    <ng-template pTemplate="item" let-item>
      <a class="menu-item p-2 no-underline text-inherit" [routerLink]="item.routerLink" [href]="item.url">
        @if (item.image) {
          <img class="w-6 h-6" [src]="item.image" alt="icon"/>
        }
        @if (item.label) {
          <span class="text-sm">{{ item.label | translate }}</span>
        }
      </a>
    </ng-template>
    <ng-content></ng-content>
  </p-menubar>
</div>
