export enum CrudEndPoint {
  ACCESSED_BRANCHES = 'company_branches/accessed',
  COMPANY_BRANCHES = 'company_branches',
  WASTE_TYPES = 'waste-types',
  COMPANIES = 'companies',
  EMPLOYEES = 'employees',
  EMPLOYEES_LOGS = 'employees/logs',
  BIN_TYPES = 'bin-types',
  POSITIONS = 'positions',
  ROLES = 'roles',
  ACCESSES = 'accesses',
  ROLE_ACCESSES = 'role-accesses',
  FLEETS = 'fleets',
  VEHICLE_TYPES = 'vehicle-types',
  FUEL_TYPES = 'fuel-types',
  LOCATION_TYPES = 'location-types',
  SERVICES = 'services',
  VEHICLES = 'vehicles',
  VEHICLES_LOGS = 'vehicles/logs',
  LOCATIONS_LOGS = 'locations/logs',
  BINS = 'bins',
  BINS_LOGS = 'bins/logs',
  TANK_DIMENSIONS = 'tank-dimensions',
  BRANDS = 'brands',
  MODELS = 'models',
  REQUEST_TYPES = 'request-types',
  REQUEST_MODES = 'request-modes',
  LOCATIONS = 'locations',
  DRIVING_LICENSES = 'driving-licenses',
  GROUPS = 'groups',
  WORKFLOW = 'workflows',
  ZONES = 'zones',
  AREAS = 'areas'
}
