@if(items.length){
<div>
  <p-menubar [styleClass]="'sub-menu'" [model]="items" [autoDisplay]="false">
    <ng-template pTemplate="item" let-item>
      <div
        class="menu-item p-4"
        (click)="onItemClick($event, item)"
        (keypress)="$event.stopPropagation()"
      >
        @if(item.image){
        <img class="w-12 h-12 rounded-full object-cover" [src]="profileImage" alt="icon" />
        } @if(item.icon){
        <span class="w-6 h-6 flex items-center justify-center" [class]="item.icon"></span>
        } @if(item.label){
        <span class="text-sm">{{ item.label | translate }}</span>
        } @if(item.checkboxGroup){
        <app-multi-checkbox [formControl]="checkControl" class="w-full" [items]="item.checkboxGroup" />
        }@if(item.radioGroup){
        <app-radio-button [formControl]="radioControl" class="w-full" [items]="item.radioGroup" />
        }
      </div>
    </ng-template>
    <ng-content></ng-content>
  </p-menubar>
</div>
}
