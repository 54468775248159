import {Routes} from '@angular/router';
import {authenticationGuard} from '@core/guards/authentication.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/user/home/home.component').then((m) => m.HomeComponent),
    canActivate: [authenticationGuard]
  },
  {
    path: 'demo',
    loadComponent: () =>
      import('./pages/demo/demo.component').then((m) => m.DemoComponent),
    canActivate: [authenticationGuard]
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then((m) => m.SettingsModule),
    canMatch: [authenticationGuard]
  },
  {
    path: 'bins',
    loadChildren: () =>
      import('./pages/bins/bins.module').then((m) => m.BinsModule),
    canMatch: [authenticationGuard]
  },
  {
    path: 'employees',
    loadChildren: () => import('./pages/employees/employees.module').then(m => m.EmployeesModule),
    canMatch: [authenticationGuard]
  },
  {
    path: 'vehicles',
    loadChildren: () => import('./pages/vehicles/vehicles.module').then(m => m.VehiclesModule),
    canMatch: [authenticationGuard]
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/user/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'locations',
    loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsModule),
    canMatch: [authenticationGuard]
  },
  {
    path: 'bins',
    loadChildren: () => import('./pages/bins/bins.module').then(m => m.BinsModule),
    canMatch: [authenticationGuard]
  },
  {
    path: 'routes',
    loadChildren: () => import('./pages/routes/routes.module').then(m => m.RoutesModule)
  },
  {
    path: 'profile',
    loadComponent: () =>
      import('./pages/user/profile/profile.component').then((m) => m.ProfileComponent),
  },
  {
    path: 'requests',
    loadChildren: () => import('./pages/requests/requests.module').then(m => m.RequestsModule)
  },
  {
    path: 'tracking',
    loadChildren: () => import('./pages/tracking/tracking.module').then((m) => m.TrackingModule),
  },
  {
    path: '**',
    redirectTo: '',
  }
];
