import {Component, Input, ViewEncapsulation} from '@angular/core';
import {NavBarItemModel} from '@core/models/navbar.model';
import {MenubarModule} from 'primeng/menubar';
import {CommonModule} from '@angular/common';
import {MultiCheckboxComponent} from '@shared/input/checkbox/multi-checkbox/multi-checkbox.component';
import {RadioButtonComponent} from '@shared/input/radio-button/radio-button.component';
import {Router} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    MenubarModule,
    CommonModule,
    MultiCheckboxComponent,
    RadioButtonComponent,
    TranslateModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent {
  @Input() items: NavBarItemModel[] = [];
  @Input() isMainItem: boolean = false;
  @Input() styleClass: string = '';

  constructor(public router: Router) {
  }
}
