import {Injectable} from '@angular/core';
import {SignalService} from '@shared/core/services/signal.service';
import {RoleReference} from '../../../pages/settings/core/role.model';
import {SUPER_USER_PERMISSION} from '../../../app.props';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private signalService: SignalService) {
  }

  saveAuthorities(authorities: {authority: string}[]): void {
    this.signalService.authorities.set(authorities.map(el => el.authority));
  }

  saveRole(role: RoleReference): void {
    this.signalService.role.set(role);
  }

  isPermitted(permission: string): boolean {
    if (permission === SUPER_USER_PERMISSION) {
      return this.signalService.role()?.name === 'ROLE_SUPERUSER';
    }
    return this.signalService.authorities().includes(permission);
  }
}
