export enum StorageKey {
  AUTHORITIES = 'authorities',
  BRANCH_MAP_PREF = 'branchMapPreference',
  COMPANY_BRANCH = 'companyBranch',
  LANG = 'lang',
  MAP_TYPE = 'mapType',
  ROLE = 'role',
  PROFILE_IMAGE = 'profileImage',
  EMPLOYEE_NAME = 'employeeName'
}
