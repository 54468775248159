import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  showSpinner=false;

  show(){
    this.showSpinner=true;
  }

  hide(){
    this.showSpinner=false;
  }
}
